<template>
  <div class="flex flex-col gap-y-2">
    <div id="payd-provisions-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="payd-provisions-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.year"
                label="Rok"
                :options="years"
                :nullOption="false"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.month"
                label="Miesiąc należności raty"
                :options="months"
                :nullOption="false"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                :nullOptionName="accessType === 'all' ? 'wszyscy' : accessType === 'subs' ? 'zespół' : ''"
              />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
        <button @click="showOrders = !showOrders" class="tw--btn tw--btn tw--btn-secondary">{{ showOrders ? 'ukryj' : 'pokaż' }} polisy</button>
      </div>
    </div>

    <div id="payd-provisions-content" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Raport naliczonej prowizji za składki opłacone {{ activeProvMonthPL.month }} {{ activeProvMonthPL.year }}</span>
      </div>

      <hr class="dark:border-gray-600 -mx-3">

      <div class="flex flex-wrap gap-x-5 gap-y-1 mt-2 mb-3">
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">
            suma {{ accessType === 'all' || accessType === 'subs' ? 'agenci' : '' }}
          </span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ sumAll.agent | moneyPL }}</span>
        </div>

        <div v-if="accessType === 'all' || accessType === 'subs'" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma wystawiający</span>
          <span class="px-3 py-1 rounded-r bg-indigo-500 dark:bg-indigo-900">{{ sumAll.copyw | moneyPL }}</span>
        </div>

        <div v-if="showAdmin" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma agencja</span>
          <span class="px-3 py-1 rounded-r bg-purple-500 dark:bg-purple-900">{{ sumAll.office | moneyPL }}</span>
        </div>
      </div>

      <!-- wrapper do tabel pracowników -->
      <div class="flex flex-col gap-y-3">

        <!-- tabela dla każdego pracownika -->
        <div v-for="employee in provisions" :key="employee.empl_id"
          class="flex flex-col divide-y border rounded-sm shadow dark:divide-gray-600 dark:border-gray-600 text-sm lg:text-xs 2xl:text-sm"
        >
          <!-- nagłówek - pracownik -->
          <div class="flex flex-wrap lg:flex-nowrap py-0.5 text-bright bg-gray-100 dark:bg-gray-800">
            <!-- employee + csv_link -->
            <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap px-2 py-1">
              <div class="flex gap-x-3">
                {{ employee.name }}
                <a :href="exportToCsv(employee.emp_id)" target="_blank" title="eksport tabeli do csv">
                  <FontAwesomeIcon :icon="['far', 'file-excel']" class="text-green-500 dark:text-green-700" />
                </a>
              </div>
            </div>
            <!-- suma -->
            <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/5 flex flex-wrap lg:flex-nowrap">
              <div class="w-full flex-grow-0 lg:w-min-0 lg:flex-grow lg:w-auto flex items-center">
                <div class="w-1/2 text-right px-1">suma</div>
                <div class="w-1/2 text-right text-bright pr-1">{{ employee.emplSum | moneyPL }}</div>
              </div>
              <div v-if="showAdmin" class="w-full lg:w-2/5 text-muted items-center px-1 flex">
                <div class="w-1/2 text-right">prow. agencja:</div>
                <div class="w-1/2 text-right">{{ employee.offSum | moneyPL }}</div>
              </div>
            </div><!-- suma -->
          </div> <!-- nagłówek - pracownik -->

          <!-- nagłówek - lista kolumn -->
          <div v-if="showOrders" class="hidden lg:flex flex-wrap lg:flex-nowrap py-0.5 text-bright bg-gray-100 dark:bg-gray-800">

            <!-- left - lp|nr polisy|tu|typ|customer -->
            <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap">

              <div class="flex-shrink-0 w-full lg:w-8 self-center px-1">lp</div>

              <!-- polisa/tu/typ -->
              <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/3 flex flex-wrap xl:flex-nowrap">
                <div class="flex-shrink-0 w-full sm:w-2/5 lg:w-full xl:w-2/5 self-center break-words px-1">nr polisy</div>
                <div class="flex-shrink-0 w-full sm:w-1/5 lg:w-2/5 xl:w-1/5 self-center px-1">tow. ubezp.</div>
                <div class="flex-shrink-0 w-full sm:w-2/5 lg:w-3/5 xl:w-2/5 self-center px-1">typ ubezp.</div>
              </div><!-- polisa/tu/typ -->

              <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow self-center px-1">klient</div>

            </div><!-- left - lp|nr polisy|tu|typ|customer -->

            <!-- right = skłądka|rola|prowizja|podział prowizji -->
            <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/5 flex flex-col lg:flex-row">
              <div class="w-full flex-grow-0 lg:w-min-0 lg:flex-grow lg:w-auto self-center text-right px-1">składka</div>
              <div class="w-full lg:w-1/5 self-center text-right px-1">rola</div>
              <div class="w-full lg:w-1/5 self-center text-right text-bright pr-1">prowizja</div>
              <div v-if="showAdmin" class="w-2/5 flex flex-wrap text-muted">
                <div class="w-full text-center border-b dark:border-gray-600 border-opacity-25 dark:border-opacity-25">podział prowizji:</div>
                <div class="w-1/3 self-center text-right px-1">agent</div>
                <div class="w-1/3 self-center text-right px-1" title="wystawiający polisę">wyst.</div>
                <div class="w-full lg:w-1/3 self-center text-right px-1">agencja</div>
              </div>
            </div><!-- right = skłądka|rola|prowizja|podział prowizji -->

          </div> <!-- nagłówek - lista kolumn -->

          <!-- wiersz - lista polis -->
          <div v-show="showOrders" v-for="(order, index) in employee.orders" :key="index" class="flex flex-wrap lg:flex-nowrap py-0.5">

            <!-- left - lp|nr polisy|tu|typ|customer -->
            <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap border-b dark:border-gray-600 border-opacity-25 dark:border-opacity-25 lg:border-0">

              <div class="flex-shrink-0 w-full lg:w-8 self-center px-1">{{ index+1 }}</div>

              <!-- polisa/tu/typ -->
              <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/3 flex flex-wrap xl:flex-nowrap">
                <div class="flex-shrink-0 w-full sm:w-2/5 lg:w-full xl:w-2/5 self-center break-words px-1">
                  <router-link :to="{name: 'Order', params: {id: order.order_id}}" target="_blank" class="tw--link">{{ order.policy_no }}</router-link>
                </div>
                <div class="flex-shrink-0 w-full sm:w-1/5 lg:w-2/5 xl:w-1/5 self-center px-1">{{ order.insurer_name }}</div>
                <div class="flex-shrink-0 w-full sm:w-2/5 lg:w-3/5 xl:w-2/5 self-center px-1">{{ order.ord_type_name }}</div>
              </div><!-- polisa/tu/typ -->

              <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow self-center px-1">{{ order.customer_name }}</div>

            </div><!-- left - lp|nr polisy|tu|typ|customer -->

            <!-- right = składka|rola|prowizja|podział prowizji -->
            <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/5 flex flex-col lg:flex-row">
              <div class="w-full flex-grow-0 lg:w-min-0 lg:flex-grow lg:w-auto self-center text-right px-1">
                <span class="lg:hidden pr-3">składka</span>{{ order.skladka | moneyPL }}
              </div>
              <div class="w-full lg:w-1/5 self-center text-right px-1">
                <span class="lg:hidden pr-3">rola</span>{{ order.role }}
              </div>
              <div class="w-full lg:w-1/5 self-center text-right text-bright pr-1">
                <span class="lg:hidden pr-3">do wypłaty</span>{{ order.to_pay_value | moneyPL }}
              </div>
              <div v-if="showAdmin" class="w-full lg:w-2/5 flex flex-col lg:flex-row text-muted">
                <div class="lg:hidden text-right pr-20 border-t dark:border-gray-600 border-opacity-25 dark:border-opacity-25">podział prowizji:</div>
                <div class="w-full lg:w-1/3 self-center text-right px-1">
                  <span class="lg:hidden pr-3">agent</span>{{ order.agent_value | moneyPL }}
                </div>
                <div class="w-full lg:w-1/3 self-center text-right px-1">
                  <span class="lg:hidden pr-3">wystawiający</span>{{ order.copyw_value | moneyPL }}
                </div>
                <div class="w-full lg:w-1/3 self-center text-right px-1">
                  <span class="lg:hidden pr-3">agencja</span>{{ order.office_value | moneyPL }}
                </div>
              </div>
            </div><!-- right = skłądka|rola|prowizja|podział prowizji -->

          </div> <!-- wiersz - lista polis -->

          <div v-if="employee.payments && employee.payments.length > 0 && showOrders" class="pl-1 italic">inne rozliczenia</div>
          <div v-show="showOrders" v-for="(payment, index) in employee.payments" :key="`paym-${index}`" class="flex flex-wrap lg:flex-nowrap py-0.5">
            <div class="flex-shrink-0 w-full lg:w-8 self-center px-1">{{ index + 1 }}</div>
            <div class="flex-grow text-bright px-1">{{ payment.description }}</div>
            <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/5 flex flex-wrap lg:flex-nowrap">
              <div class="w-full lg:w-auto lg:flex-grow self-center text-right text-bright px-1"><span class="lg:hidden pr-3">do wypłaty</span>{{ payment.amount | moneyPL }}</div>
              <div v-show="showAdmin" class="w-full lg:w-2/5 flex flex-col lg:flex-row text-muted">
                <div class="w-full lg:w-1/3 self-center text-right px-1"><span class="lg:hidden pr-3">agent</span>{{ payment.amount | moneyPL }}</div>
                <div class="w-full lg:w-1/3 self-center text-right px-1"></div>
                <div class="w-full lg:w-1/3 self-center text-right px-1"><span class="lg:hidden pr-3">agencja</span>{{ -payment.amount | moneyPL }}</div>
              </div>
            </div>
          </div>

          <!-- podsumowanie - pracownik -->
          <div v-if="showOrders" class="flex flex-wrap lg:flex-nowrap py-0.5 text-bright bg-gray-100 dark:bg-gray-800">
            <!-- employee + csv_link -->
            <div class="w-full flex-grow-0 lg:min-w-0 lg:flex-grow flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap px-2 py-1">
              <div class="flex gap-x-3">
                podsumowanie dla: {{ employee.name }}
                <a :href="exportToCsv(employee.emp_id)" target="_blank" title="eksport tabeli do csv">
                  <FontAwesomeIcon :icon="['far', 'file-excel']" class="text-green-500 dark:text-green-700" />
                </a>
              </div>
            </div>
            <!-- suma -->
            <div class="flex-shrink-0 w-full lg:w-1/2 xl:w-2/5 flex flex-wrap lg:flex-nowrap">
              <div class="w-full flex-grow-0 lg:w-min-0 lg:flex-grow lg:w-auto flex items-center">
                <div class="w-1/2 text-right px-1">suma</div>
                <div class="w-1/2 text-right text-bright pr-1">{{ employee.emplSum | moneyPL }}</div>
              </div>
              <div v-if="showAdmin" class="w-full lg:w-2/5 text-muted items-center px-1 flex">
                <div class="w-1/2 text-right">prow. agencja:</div>
                <div class="w-1/2 text-right">{{ employee.offSum | moneyPL }}</div>
              </div>
            </div><!-- suma -->
          </div> <!-- podsumowanie - pracownik -->

        </div><!-- tabela dla każdego pracownika -->

      </div><!-- wrapper do tabel pracowników -->

    </div>


  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import moment from 'moment';
import ProvisionService from '../../services/ProvisionService';

export default {
  name: 'ListPaydProvisions',
  mixins: [errorHandler],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      provisions: [],
      accessType: '',
      sumAll: {
        agent: 0,
        office: 0,
      },
      activeFilters: {
        year: '',
        month: '',
        employee_id: '',
      },
      showOrders: true,
      saveErrors: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
      // return false;
    },
    years() {
      const startY = moment().subtract(1, 'years').format('YYYY');
      const yearsArr = [];
      for (let i = 0; i < 10; i += 1) {
        yearsArr.push({ id: Number(startY) + i, name: Number(startY) + i });
      }
      return yearsArr;
    },
    months() {
      const months = [];
      for (let index = 1; index < 13; index++) {
        const month = (`${index}`).length === 1 ? `0${index}` : `${index}`;
        months.push({ id: month, name: `${index}` });
        // months.push({ id: index, name: index });
      }
      return months;
    },
    activeProvMonthPL() {
      if (this.activeFilters.year === '' || this.activeFilters.month === '') {
        return { month: '', year: '' };
      }
      const mc = ['w styczniu', 'w lutym', 'w marcu', 'w kwietniu', 'w maju', 'w czerwcu', 'w lipcu', 'w sierpniu', 'we wrześniu', 'w październiku', 'w listopadzie', 'w grudniu'];
      const prevM = moment(`${this.activeFilters.year}-${this.activeFilters.month}-01`).subtract(1, 'month');
      const provY = prevM.format('YYYY');
      const provM = Number(prevM.format('M')) - 1;
      return { month: mc[provM], year: provY };
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    this.activeFilters.year = moment().format('YYYY');
    this.activeFilters.month = moment().format('MM');
    this.init();
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.getListPaydProvisions(this.activeFilters)
        .then((response) => {
          this.accessType = response.data.access_type;
          this.provisions = response.data.provisions;
          this.sumAll = response.data.sumAll;
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    exportToCsv(empployeeId) {
      return `${this.apiUrl}/export-emp-payd-provisions-to-csv/${empployeeId}?year=${this.activeFilters.year}&month=${this.activeFilters.month}`;
    },
    prevMonth() {
      const pevDate = moment(`${this.activeFilters.year}-${this.activeFilters.month}-01`).subtract(1, 'months');
      this.activeFilters.year = moment(pevDate).format('YYYY');
      this.activeFilters.month = moment(pevDate).format('MM');
      this.getReport();
    },
    setToday() {
      this.activeFilters.year = moment().format('YYYY');
      this.activeFilters.month = moment().format('MM');
      this.getReport();
    },
    nextMonth() {
      const nextDate = moment(`${this.activeFilters.year}-${this.activeFilters.month}-01`).add(1, 'months');
      this.activeFilters.year = moment(nextDate).format('YYYY');
      this.activeFilters.month = moment(nextDate).format('MM');
      this.getReport();
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'ListPaydProvisions', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setToday();
      this.getReport();
    },
  },
};
</script>